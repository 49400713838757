import { Button, ButtonMenuItem, Flex, Heading, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import styled, { css } from 'styled-components'
import { CommunityTags } from './CommunityTags'
import { LeftBottomBox, RightBottomBox } from './ImagesOnBg';
import tokenListingImg from '../../../../public/images/token-listing.png';
import Image from 'next/image';

const TransparentFrame = styled.div<{ isDark: boolean }>`
  position: relative;
  z-index: 2;
  background: ${({ theme }) => (theme.isDark ? 'rgba(0, 0, 0, 0.80)' : ' rgb(235 239 245)')};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  border-radius: 72px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 40px;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

export const sharedCss = css`
  @media screen and (max-width: 396px) {
    width: calc(100vw - 70px);
  }
  @media screen and (max-width: 762px) and (min-width: 700px) {
    width: calc(50vw - 50px);
  }
`

const Wrapper = styled.div`
  border-radius: 32px;
  border: 2px solid #180f28;
  background: #180f28;
  padding: 24px;
  box-sizing: border-box;
  width: 340px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 340px;
    padding: 24px 0px 24px 60px;
    height: 340px;
  }
  ${sharedCss}
`

const SecondWrapper = styled.div`
  border-radius: 32px;
  border: ${({ theme }) => `2px solid ${theme.colors.backgroundAlt2}`};
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  box-shadow: ${({ theme }) => `0px 4px 0px 0px ${theme.colors.backgroundAlt2}`};
  padding: 24px;
  box-sizing: border-box;
  width: 340px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 340px;
    height: 340px;
  }
  ${sharedCss}
`

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.warning};
  font-weight: 600;
  line-height: 110%;
  font-size: 40px;
`


const CommunitySummary = () => {
  return (
    <Wrapper>
      <Flex flexDirection="column">
        <Text color="white" bold fontSize="16px">
          Transaction Target
        </Text>
        <StyledText fontSize="40px" bold mb="27px">
          1.5M +
        </StyledText>
        <Text color="white" bold fontSize="16px">
          List Target
        </Text>
        <StyledText fontSize="40px" bold mb="27px">
          10k +
        </StyledText>
        <Text color="white" bold fontSize="16px">
          Project Us
        </Text>
        <StyledText fontSize="40px" bold mb="27px">
          10 +
        </StyledText>
      </Flex>
    </Wrapper>
  )
}



const CommunitySection = () => {
  const { theme } = useTheme()
  const { isMobile } = useMatchBreakpoints()

  return (
    <>
      <BgWrapper>
        <RightBottomBox />
        <LeftBottomBox />
      </BgWrapper>
      <TransparentFrame isDark={theme.isDark}>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Flex
            style={{ gap: isMobile ? 0 : 8 }}
            justifyContent="center"
            alignItems="center"
            flexDirection={isMobile ? 'column' : 'row'}
            mb="12px"
          >
            <Heading scale="xl">Unlock the Full</Heading>{' '}
            <Heading color="#ff9327" scale="xl">
              Potential of DeFi
            </Heading>
          </Flex>
          <Text mb="40px" color="textSubtle" fontWeight={600} textAlign="center">
            Join us in growing the AOMZ DeFi exchange. Welcome a partners.
          </Text>
          <Flex flexDirection="row" flexWrap="wrap" alignItems="center" style={{ gap: 24 }} justifyContent="center">
            <CommunitySummary />
            <SecondWrapper style={{ textAlign: "center" }}>
              <Text textTransform='uppercase' marginBottom="10px" bold fontSize="14px" textAlign="center">
                Apply To List Logo <Text color='primary'>Your Token</Text>
              </Text>
              <Image src={tokenListingImg} height="550px" alt='token-list' />
              <a href='https://www.linkedin.com/in/anatolii-kosorukov-444a97109' target='_blank'>
                <Button
                  scale="md"
                  variant="primary"
                  marginTop="10px"
                >
                  Contact Us
                </Button>
              </a>
            </SecondWrapper>
          </Flex>
        </Flex>
      </TransparentFrame>
      <CommunityTags />
    </>
  )
}

export default CommunitySection;


