import { Button, Flex, OpenNewIcon, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'



const BunnyWrapper = styled.div`
  width: 100%;
  animation: 3.5s ease-in-out 0s infinite;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const StyledText = styled(Text)`
  font-size: 25px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 32px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 40px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    font-size: 40px;
  }
`

const CommonSection = ({ title, description, buttonText, imageSrc, isReverse = false, learnLink = "#sec", buttonLink = '/swap' }) => {
  const { theme } = useTheme()
  const { isMobile, isXs, isMd } = useMatchBreakpoints()

  return (
    <Flex
      position="relative"
      flexDirection={isReverse ? ['column', null, null, 'row-reverse'] : ['column-reverse', null, null, 'row']}
      alignItems={['center', null, null, 'center']}
      justifyContent="center"
      mt={['50px', null, 0]}
      pl={['0px', '0px', '0px', '30px']}
      id="homepage-hero"
    >
      <Flex flex="1" flexDirection="column"  marginLeft={isMobile || isMd ? "0px" : isReverse ? "64px" : "0px"} marginRight={isMobile || isMd ? "0px" : !isReverse ? "64px" : "0px"}>
        <Text textAlign={isMobile || isMd ? 'center' : 'left'} fontSize="15px" mb="16px">
          <StyledText style={{ color: "#ff9327" }} display="inline-block" lineHeight="110%" fontWeight={600} color="text" mr="8px">
            {title}
          </StyledText>
        </Text>
        <Text
          mb="24px"
          color={theme.isDark ? '#f4eeff' : '#f4eeff'}
          maxWidth={600}
          fontSize={['16px', '16px', null, '16px']}
          textAlign={isMobile ? 'center' : 'left'}
          fontWeight={300}
        >
          {description}
        </Text>
        <Flex justifyContent={isMobile || isMd ? 'center' : 'start'} style={{ gap: 14 }}>
          <Link href={buttonLink}>
            <Button
              scale="md"
              style={{ borderRadius: isXs ? 12 : undefined }}
              variant="primary"
            >
              {buttonText}
            </Button>
          </Link>
          <a href={learnLink} target='_blank' >
            <Button pl="0" endIcon={<OpenNewIcon color="primary" />} variant="text">
              Learn
            </Button>

          </a>
        </Flex>
      </Flex>
      <Flex
        height={['100%', null, null, '100%']}
        width={['100%', null, null, '100%']}
        flex={[null, null, null, '1']}
        mb={['24px', null, null, '0']}
        position="relative"
        marginLeft={isMobile || isMd ? "0px" : isReverse ? "64px" : "0px"}
        marginRight={isMobile || isMd ? "0px" : !isReverse ? "64px" : "0px"}
      >
        <BunnyWrapper>
          <Image src={imageSrc} priority alt='Dynamic Image' />
        </BunnyWrapper>
      </Flex>
    </Flex>
  )
}

export default CommonSection
