import {
  DiscordIcon,
  FacebookIcon,
  Link,
  MediumIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon
} from '@pancakeswap/uikit'
import { cloneElement } from 'react'
import styled  from 'styled-components'

const CommunityWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 12px;
  justify-content: center;
`
const CommunityTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  width: 32px;
  height: 32px;
  transition: background-color 0.25s ease-in-out;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
    transition: fill 0.25s ease-in-out;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    svg path {
      fill: ${({ theme }) => (theme.isDark ? '#000000' : '#ffffff')};
    }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 48px;
    height: 48px;
  }
`
const communityData = [
  { icon: <FacebookIcon />, href: 'https://www.facebook.com/Aomziip', alt: 'FacebookIcon' },
  { icon: <TwitterIcon />, href: 'https://x.com/Aomziip', alt: 'TwitterIcon' },
  { icon: <TelegramIcon />, href: 'https://t.me/aomziip', alt: 'TelegramIcon' },
  // { icon: <DiscordIcon />, href: 'https://discord.gg/ARqdjAyX', alt: 'DiscordIcon' },
  // { icon: <RedditIcon />, href: 'https://www.reddit.com/u/TORNADOSWAP/s/weZ8FswS6U', alt: 'RedditIcon' },
  // { icon: <YoutubeIcon />, href: 'https://www.youtube.com/channel/UC94EOeU2XOIX6sLqywQ3Fcg', alt: 'youtubeIcon' },
  // { icon: <MediumIcon />, href: 'https://medium.com/@tornadoswap9168', alt: 'mediumIcon' },
]

export const CommunityTags: React.FC = () => {
  return (
    <CommunityWrapper>
      {communityData.map((item) => (
        <Link href={item.href} key={item.alt} external>
          <CommunityTag>{cloneElement(item.icon, { width: 18 })}</CommunityTag>
        </Link>
      ))}
    </CommunityWrapper>
  )
}
